.about_me_container {
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 80%;
    gap: 1vh;
    animation: fade-in 2s;
}

.ls_aboutme img {
    border-radius: 2vh;
}

.rs_aboutme {
    background-color: #F1AEA4;
    max-width: 70%;
    padding: 2vh;
    border-radius: 2vh;
}

.title {
    font-size: 36px;
}

.text_1 {
    font-size: 28px;
}

.text_2 {
    font-size: 24px;
}

.subtitle {
    font-size: 30px;
}

.subtitle_2 {
    font-style: bold underline;
    font-size: 40px;
}

.klaptrap_button_container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.klaptrap_button {
    background-color: transparent;
    animation: bounce-down forwards 0.25s;
    border: none;
    max-width: 64px;
}

.klaptrap_button:hover {
    animation: bounce-up forwards 0.25s;
}

@keyframes bounce-up {
    from { transform: translateY(0); }
    to { transform: translateY(-1vh) };
}

@keyframes bounce-down {
    from { transform: translateY(-1vh) };
    to { transform: translateY(0); }
}

.klaptrap_releases_container {
    display: flex;
    gap: 1vh;
    flex-wrap: wrap;
    margin-top: 1vh;
    justify-content: center;
}

.klaptrap_releases_container .album_link_div {
    max-width: 40%;
    min-width: 20%;
}

.simple_container {
    display: flex;
    background-color: pink;
    padding: 5px;
}

.simple_container img {
    max-width: 30%;
    aspect-ratio: 5 / 1;
    margin-left: auto;
    border-style: solid;
}

.cat_photo_container {
    min-width: 30%;
    text-align: center;
    gap: 0;
    padding: 2vh;
    font-size: 24px;
}

.cat_photo_container .cat_photo {
    width: 200px;
    height: 300px;
}

.layeredgif {
    position: absolute;
    height: 200px;
    animation: zoom-in infinite 2s ease-in-out;
}

@keyframes zoom-in {
    0% {transform: scale(1)}
    50% {transform: scale(1.5)}
    100% {transform: scale(1)}
}

@keyframes fade-in {
    0% {opacity: 0}
    100% {opacity: 100}
}