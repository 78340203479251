.nav_bar_button {
    background-color: white;
    color: black;
    border-radius: 10px;
}

.WrinklyNavBar {
    offset: 50px;
    text-align: center;
}
