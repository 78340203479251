@font-face {
    font-family: "Linux Libertine";
    src: url('../fonts/LinuxLibertineDisplay-23pl.ttf');
}

.container_container {
    max-width: 70%;
    margin: auto;
    display: flex;
    gap: 2vh;
    font-family: "Linux Libertine";
    animation: transitionIn 1s;
}

.right_side_container {
    border-radius: 1vh;
    padding: 2vh;
    background-color: #DD9B8E;
    flex: 1;
    color:white;
}

.left_side_container {
    display: inline-flex;
    flex-direction: column;
    max-width: 30%;
    gap: 0.5vh;
}

.left_side_container .john_photo {
    border-radius: 1vh;
}

.skills_div {
    border-radius: 1vh;
    padding: 2vh;
    background-color: #F1AEA4;
}

.skills_list {
    display: flex;
    width: 100%;
    gap: 2vh;
    justify-content: center;
    flex-wrap: wrap;
}

.skills_content {
    animation: transitionIn 1.5s;
}

.skills_list .skill {
    font-size: 2vh;
    line-height: 2vh;
}

.objective_div {
}

.job_experience_div {
    animation: transitionIn 2s;
}

.projects_div {
    animation: transitionIn 2.5s;
}

.section_title {
    font-size: 3vh;
}

.objective {
    font-size: 2vh;
}

.education_div {
    animation: transitionIn 1.5s;
}

.description_div {
    font-size: 2.5vh;
    line-height: 2.5vh;
}

.profile_pic_div {
    max-width: 30%;
}

.resume_object {
    padding-top: 1vh;
    padding-bottom: 1vh;
}

@keyframes transitionIn {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}

@keyframes bounce-up {
    from { transform: translateY(0); }
    to { transform: translateY(-1vh) };
}

@keyframes bounce-down {
    to { transform: translateY(0); }
    from { transform: translateY(-1vh) };
}

.resume_button_container {
    display: flex;
    justify-content: center;
    gap: 0.5vh;
    margin: 0;
}

.resume_button_container .resume_button {
    max-width: 64px;
    max-height: 64px;
    background-color: transparent;
    border: none;
}

.resume_button {
    animation: bounce-down 0.25s ease;
}

.resume_button:hover {
    animation: bounce-up 0.25s forwards ease;
}

.download_button {
    max-width: 128px;
    background-color: transparent;
    border: none;
    animation: bounce-down 0.25s forwards ease;
}

.download_button:hover {
    animation: bounce-up 0.25s forwards ease;
}