.BeerFrontPage {
    margin: auto;
    width: 1264px;
    height: 1262px;
    position: relative;
    left: 0;
    bottom: 0;
    image-rendering: pixelated;
}

.fridge_image {
    flex: 1;
    object-fit: cover;
    position: absolute;
    width: 1264px;
    height: 1262px;
    left: 0;
}

.beer_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: absolute;
    left: 90px;
    top: 110px;
    row-gap: 40px;
}

.BeerPage {
    max-width: 50%;
}

.BeerPage > PostCardList {
    text-align: center;
    flex: 2;
}

.navButton {
    margin: 0 auto;
    float: none;
    margin-bottom: 10px;
    text-align: center;
}

.buttonFooter {
    margin: auto;
    width: 15%;
    display: flex;
}

.helpme {
    background-image: url("../pixelart/fridge/tile.png");
    background-size: 256px;
}