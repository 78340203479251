.loading {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

.loading_gif {
    width: 5vh;
    height: 5vh;
}