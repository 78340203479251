.ProjectCard {
    box-shadow: 2px 2px 4px 1px beige;
    border-radius: 15px;
    animation: transitionIn 1000ms;
    animation: bounce-down forwards 250ms;
}

.ProjectCard:hover {
    animation: bounce-up forwards 250ms;
}

.project_card_wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
    justify-content: center;
    margin: auto;
    gap: 1vh;
}

.project_card_wrapper > ProjectCard {
    margin: 30px;
    padding: 50px;
    font-size: 30px;
}

.project_page_box {
}

.project_card_image {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.button_container {
    display: flex;
    flex-wrap: flex;
    height: 100%;
    width: 100%;
}

.button_container > .button_in_row {
    max-block-size: 5vh;
    max-width: 20%;
    max-height: 20%;
}

.git_button {
    border: none;
    background-color: white;
}

.img_logo {
    max-width: 100%;
}

.project_card_date {
    font-size: 15;
    font-style: oblique;
    font-weight: 100;
}

.project_card_tags {
    display: flex;
    flex-wrap: wrap;
}

.project_card_contents {
    margin: auto;
    flex: 1;
}

.label_button {
    border-radius: 10px;
    font-size: 1vh;
}

@keyframes transitionIn {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}


@keyframes bounce-up {
    from { transform: translateY(0); }
    to { transform: translateY(-1vh) };
}

@keyframes bounce-down {
    from { transform: translateY(-1vh) };
    to { transform: translateY(0); }
}
