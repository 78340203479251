.wk_footer_container {
    display: flex;
    margin: auto;
    width: 50%;
}

.wk_footer_container > .wk_footer_content {
    text-align: center;
    margin: auto;
}

.helpme {
    background-color: black;
    color: white;
    height: 100%;
    min-height: 100vh;
}