.beercontainer {
}

.beercontainer img {
    width: 270px;
    height: 405px;
}

.beercontainer:hover {
    filter: drop-shadow(0px 0px 30px red);
    cursor: pointer;
}