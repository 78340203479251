#beer_photo {
    box-shadow: 0 0 3px #ddd;
    float: right;
    width: 25%;
    height: 25%;
}

#beer_post_shell {
    max-width: 75%;
    margin: auto;
    display: block;
    background-color: gray;
    padding: 20px;
    border-radius: 25px;
    border-style: solid;
    border-color: beige;
    border-width: 35px;
}

#beer_title {
    text-align: left;
    font-size: 84px;
}

#beer_post_details {
    display: inline-block;
    text-align: left;
    width: 75%;
    font-size: 35px;
}

#beer_post_details h1 {
    font-size: 60px;
}

#beer_post_details h2 {
    font-size: 52px;
}

#beer_post_details h3 {
    font-size: 45px;
}

#beer_post_details p {
    left: 100px;
    text-indent: 100px;
}

#beer_post_details .rating {
    font-size: 1000px;
}