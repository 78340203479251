@font-face {
    font-family: "Linux Libertine";
    src: url('../fonts/LinuxLibertineDisplay-23pl.ttf');
}

.page_not_found {
    text-align: center;
    justify-content: center;
}

.not_found_title {
    font-size: 15vh;
    font-family: "Linux Libertine";
    animation: transitionIn 1s ease-in-out;
}

.headbanggif {
    animation: moveIn 1s;
}

@keyframes transitionIn {
    from {
        opacity: 0;
        transform: rotateX(-20deg);
    }
  
    to {
        opacity: 1;
        transform: rotateX(0);
    }
}

@keyframes moveIn {
    from {
        opacity: 0;
        transform: rotateY(-360deg);
    }
  
    to {
        opacity: 1;
        transform: rotateY(0);
    }
}

