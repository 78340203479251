.main_page_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vh;
}

.main_display_page {
    width: 100vh;
    height: 60vh;
    overflow: hidden;
    position: relative;
}

.main_display_page img {
    width: 100vh;
    height: 60vh;
    object-fit: cover;
    opacity: 0.8;
    position: absolute;
}

.main_display_page .p_title {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 5vh;
    color: white;
    text-shadow: 0px 0px 1vh black;
}

.main_display_page .p_desc {
    font-size: 2vh;
}

.page_container {
    background-color: green;
    display: flex;
    gap: 1vh;
}

.page_child {
    background-color: purple;
}