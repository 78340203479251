.job_title {
    font-size: 24px;
}

.job_description {
    font-size: 20px;
}

.resume_object_content_container {
    display: flex;
    gap: 20px;
    font-size: 2.5vh;
    line-height: 2.5vh
}

.left_side_resume_object {
    min-width: 20vh;
}

.right_side_resume_object {
    flex: 1;
}

.experience_sub_info {
    font-size: 2vh;
    line-height: 1.5vh;
    margin-bottom: 1vh;
    font-style: italic;
}

.date_div {
    font-style: italic;
    font-size: 2vh;
}

.description_div {
    font-size: 2vh;
    line-height: 2vh;
}