@font-face {
    font-family: "Linux Libertine";
    src: url('../fonts/LinuxLibertineDisplay-23pl.ttf');
}

img {
    max-width: 100%;
}

.album_link_div {
    text-decoration: none;
    color: white;
    background-color: #DD9B8E;
    text-align: center;
    border-radius: 1.5vh;
    font-family: "Linux Libertine";
    animation: bounce-down forwards ease 0.5s;
}

.album_link_div:hover {
    animation: bounce-up forwards ease 0.5s;
}

.title_div {
    font-size: 24px;
}

.release_info_div {
    font-size: 20px;
}

@keyframes bounce-up {
    from { transform: translateY(0); }
    to { transform: translateY(-1vh) };
}

@keyframes bounce-down {
    from { transform: translateY(-1vh) };
    to { transform: translateY(0); }
}