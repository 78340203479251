.main_container {
    margin: auto;
    width: 1920px;
    height: 1090px;
    position: relative;
    left: 0;
    bottom: 0;
    object-fit: cover;
    image-rendering: pixelated;
    border: solid;
    border-color: beige;
}

.background {
    order: 1;
    flex: 1;
    object-fit: cover;
    width: 1920px;
    height: 1080px;
}

.hand {
    scale: 1.5;
    order: 4;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 800px;
    top: 480px;
    transition-duration: 250ms;
}

.draftbeer {
    scale: 1.5;
    order: 10;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 448px;
    top: 180px;
    transition-duration: 250ms;
}

.me {
    scale: 1.5;
    order: 5;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 450px;
    top: 489px;
    transition-duration: 250ms;
}

.lights {
    scale: 1.5;
    order: 4;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 320px;
    top: 179px;
    transition-duration: 250ms;
    mix-blend-mode: soft-light;
}

.lightfixtures {
    scale: 1.5;
    order: 5;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 320px;
    top: 180px;
}

.guypainting {
    scale: 1.5;
    order: 2;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 1036px;
    top: 164px;
    transition-duration: 250ms;
}

.guyhighlight {
    scale: 1.5;
    order: 2;
    flex: 1;
    object-fit: none;
    position: absolute;
    left: 1036px;
    top: 164px;
    transition-duration: 250ms;
}

.latestbeer {
    opacity: 0;
    scale: 2;
    order: 10;
    flex: 1;
    object-fit: none;
    position: absolute;
    transition-duration: 100ms;
    left: 1090px;
    top: 260px;
}

.hand:hover {
    transition-duration: 150ms;
    scale: 1.6;
    filter: drop-shadow(0px 0px 30px red);
    cursor: pointer;
}

.hand:hover + .latestbeer {
    opacity: 1;
}

.allbeer {
    opacity: 0;
    scale: 2;
    order: 10;
    flex: 1;
    object-fit: none;
    position: absolute;
    transition-duration: 250ms;
    left: 900px;
    top: 75px;
}

.draftbeer:hover + .allbeer {
    opacity: 1;
}

.draftbeer:hover {
    filter: drop-shadow(0px 0px 40px red);
    cursor: pointer;
}